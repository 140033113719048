<template>
    <div>
        <div
            v-if="imageUrl"
            class="d-flex justify-center mx-0 mt-2 mb-8 pa-md-4 px-6 image-container"
        >
            <v-img
                contain
                width="300"
                height="220"
                :src="imageUrl"
            ></v-img>
            <v-btn
                class="remove-media"
                icon
                depressed
                @click="removeImage()"
            >
                <v-icon color="warning">
                    fa-solid fa-x
                </v-icon>
            </v-btn>
        </div>
        <v-file-input
            v-else
            v-model="value"
            outlined
            class="file-input"
            color="primary"
            :placeholder="$t(`Admin.CreateEditDih.form.image`)"
            :accept="acceptedFileTYpes"
            prepend-inner-icon="fa-solid fa-image"
            required
            :hint="$t(`Admin.CreateEditDih.form.fileInputHint`)"
            persistent-hint
            :error-messages="imageErrors"
            @input="
                $v.value.$touch();
                inputMedia();
            "
            @change="
                $v.value.$touch();
                inputMedia();
            "
        >
            <template v-slot:selection="{ text }">
                <v-chip
                    color="primary"
                    dark
                    label
                    small
                >
                    {{ text }}
                </v-chip>
            </template>
        </v-file-input>
        <p 
         v-if="validation.image_file"
         style="margin-top: -5px !important;"
         class="ml-3 pb-3 error--text text-caption font-weight-normal"
        >
            {{ validation.image_file[0] }}
        </p>
        <p
         v-if="validation.image_url"
         style="margin-top: -5px !important;"
         class="ml-3 pb-3 error--text text-caption font-weight-normal"
        >
            {{ validation.image_url[0] }}
        </p>
    </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { blobToBase64 } from "@/helpers/general.js"
export default {
    name: "UploadInput",

    validations: {
        value: {
            required,
        },
    },

    props: {
        validation: {
            type: [Array, Object, File],
            required: false,
            default: function() {
                return {}
            },
        },
        currentImage: {
            type: [String, File, Object],
            required: false,
            default: ''
        }
    },

    data() {
        return {
            acceptedFileTYpes: "image/*",
            imageUrl: this.currentImage || null,
            value: null,
        };
    },

    computed: {
        imageErrors() {
            const errors = [];
            if (!this.$v.value.$dirty) return errors;
            !this.$v.value.required &&
                errors.push(this.$i18n.t(`ErrorMessages.required`));
                if(this.validation.image_file) {
                    errors.push(this.validation.image_file[0]);
                }
                if(this.validation.image_url) {
                    errors.push(this.validation.image_url[0]);
                }
            return errors;
        },
    },

    methods: {
        inputMedia() {
            let media = this.value;
            // blobToBase64(media).then((resault) => {
            //     this.imageUrl = resault;
            // });

            this.$emit("update:image-url", media);
            this.$emit("update:in-valid", this.$v.$invalid);
        },

        removeImage() {
            this.imageUrl = null;
            this.value = null;
            this.$emit("update:in-valid", this.$v.$invalid);
            this.$emit("delete-image", '');
        },
    },

    watch: {
        currentImage(newVal) {
            if (newVal && typeof newVal != 'string' ) {
                blobToBase64(newVal).then((resault) => {
                this.imageUrl = resault;
              });
            } else {
                this.imageUrl = newVal;
            }
        },
        value(newVal) {
            this.$v.$reset();
        },
    },
}
</script>

<style lang="scss">
.image-container {
    border: 1px solid #9e9e9e;
    border-radius: .4em;
}

/*Styles for centered input icon*/

.v-text-field .v-input__prepend-inner, .v-text-field .v-input__append-inner{
    align-self: center;
}
</style>