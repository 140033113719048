export const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
};

export const createErrorsArray = (errors) => {
    return Object.entries(errors).reduce((acc, [key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((err) => acc.push(err));
        } else acc.push(err);
        return acc;
    }, []);

};
